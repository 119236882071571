






















































































import { Component, Vue } from 'vue-property-decorator';
import ViewTitle from '@/components/ViewTitle.vue';

export default {
  components: {
    ViewTitle,
  },
};
